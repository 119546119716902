import React from 'react';
import logo from '../images/logo.gif';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import YearsLogo from '../images/20-years-logo.png';
import { FaFacebookF, FaXTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa6';
import { AiFillInstagram } from "react-icons/ai";
// import * as Scroll from 'react-scroll';

import '../css/ribbon.css';
import '../css/sidebar.css';

// let LinkScroll = Scroll.Link;
// let AnimateScroll = Scroll.animateScroll;


function SideMenu() {
    return(
        <>
            <Navbar expand="md" variant="light" className="bg-faded justify-content-center">
                <Navbar.Brand as={Link} to="/">
                    <img alt="" src={logo}
                        height="120"
                        className="d-inline-block align-top com-logo"  data-toggle="collapse"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="w-100" id="responsive-navbar-nav" style={{padding:"10px"}}>
                    <Nav className="w-100 justify-content-center">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/Functions">Functions</Nav.Link>
                        <NavDropdown title="Life@XLD" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/Culture">Culture</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Leadership">Leadership</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Growth">Growth</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Facilities">Facilities</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/MeetOurPeople"> Meet Our People </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Testimonials">View All Testimonials</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/Careers">Careers</Nav.Link>
                        <Nav.Link as={Link} to="/PressReleases">Press Releases</Nav.Link>
                        <Nav.Link as={Link} to="/Others">Others</Nav.Link>
                        <Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link>
                    </Nav>
                    <Nav className="justify-content-end yearsOfCompletion">
                        <Image src={YearsLogo} style={{height:'131px'}} alt="XL Dynamics Celebrates Over 20 Years of Excellence" title="XL Dynamics Celebrates Over 20 Years of Excellence" />
                    </Nav>
                    <div className="social_links">
                        {/* <ul style="margin: -42px -22px 0 0;"> */}
                        <ul>
                            <li><a target="_blank" href="https://www.instagram.com/xldynamics/" rel="noopener noreferrer" title="Tag us on Instagram!" alt="Tag us on Instagram!"><AiFillInstagram size={22} /></a></li>
                            <li><a target="_blank" href="https://www.youtube.com/channel/UCLDjW6JKD12MaulN-Nk_IvQ" rel="noopener noreferrer" title="Watch us on YouTube!" alt="Watch us on YouTube!"><FaYoutube size={22} /></a></li>
                            <li><a target="_blank" href="https://www.facebook.com/XLDynamicsindia" rel="noopener noreferrer" title="Like us on Facebook!" alt="Like us on Facebook!"><FaFacebookF size={22} /></a></li>
                            <li><a target="_blank" href="https://twitter.com/XLDynamics" rel="noopener noreferrer" title="Follow us on Twitter!" alt="Follow us on Twitter!"><FaXTwitter size={22} /></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/company/xl-dynamics-india-pvt.-ltd." rel="noopener noreferrer" title="Follow us on LinkedIn!" alt="Follow us on LinkedIn!"><FaLinkedinIn size={22} /></a></li>
                        </ul>
                    </div>
                    {/* <Nav className="w-100 justify-content-end yearsOfCompletion">
                        <Nav.Link eventKey={2}>
                            <div style={{width: '220px', height: '110px', margin: 'auto', background: 'rgb(255,214,94)',
                                    background: "none",
                                    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd65e", endColorstr="#febf04",GradientType=0 )'}}>
                                <h1 className="text-center" style={{fontSize: "6.5rem",color:"firebrick"}}>15</h1>
                            </div>
                            <div className="ribbon-wrapper"><div className="glow">&nbsp;</div>
                                <div className="ribbon-front">YEARS OF EXPERIENCE</div>
                                <div className="ribbon-edge-topleft"></div>
                                <div className="ribbon-edge-topright"></div>
                                <div className="ribbon-edge-bottomleft"></div>
                                <div className="ribbon-edge-bottomright"></div>
                            </div>
                        </Nav.Link>
                    </Nav> */}
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
export default SideMenu;












// import { slide as Menu} from 'react-burger-menu';

// export default props => {
//     return(
//         <Menu {...props}>
//             <a className="menu-item" href="/">Home</a>
            
//             <a className="menu-item" href="/Funtions">Funtions</a>
            
//             <a className="menu-item" href="/Life@XLD">Life @ XLD</a>
            
//             <a className="menu-item" href="/Careers">Careers</a>
            
//             <a className="menu-item" href="ContactUs">Contact Us</a>
//         </Menu>
//     );
// }